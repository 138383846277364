import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuBottomTeacherComponent as childComponent } from '../_template-parts/menu-bottom-teacher.component';
import 'rxjs/add/operator/toPromise';
import { HangSo } from '../_models/constant';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-teacher-chat',
  templateUrl: './teacher-chat.component.html',
  styleUrls: ['./teacher-chat.component.css']
})
export class TeacherChatComponent implements OnInit {
  @ViewChild(childComponent)
  mychild: childComponent;

  loading: any;
  token: string;
  currentUserID: number;
  app_data: any;
  list_class: any;
  list_student_just_send: any;

  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;
  items_tg: any;
  db: AngularFireDatabase;
  constructor(
    public hs: HangSo,
    db_const: AngularFireDatabase
  ) {
    this.db = db_const;
  }

  onAddForChild(key_chat: string, chat_with: string) {
    this.mychild.header_chat = 'GV <strong>vs</strong> ' + chat_with;
    this.mychild.set_chat_with_person(key_chat, 100);
    this.mychild.show_form = true;

    let x: any;
    x = {'key_chat': key_chat, 'people_value': chat_with, 'header_chat': this.mychild.header_chat};
    if (JSON.stringify(this.mychild.list_current_chat).indexOf(JSON.stringify(x)) !== -1) {
      const index = this.mychild.list_current_chat.indexOf(x);
      this.mychild.list_current_chat.splice(index, 1);
    }
    // them lai hoac them moi vao cuoi mang
    this.mychild.list_current_chat.push(x);
    localStorage.setItem('list_current_chat', JSON.stringify(this.mychild.list_current_chat));
  }

  ngOnInit() {
    this.token = this.hs.token2018();
    this.currentUserID = parseInt(localStorage.getItem('currentUserID'), 0);
    this.app_data = JSON.parse(localStorage.getItem('app_data'));
    this.list_class = this.app_data.list_class;

    this.set_list_class_chat();
  }

  set_list_class_chat() {
    /*
    - neu 1 PH(1 con) vua gui tin nhan cho GV, ma GV chua kip doc thi
      thanh vien nay se tu dong cho vao danh sach nay
    - nguoc lai: bi loai khoi danh sach
    */
    this.list_student_just_send = [];

    let tg_list_class: any;
    tg_list_class = [];
    let class_stt = -1;
    this.list_class.forEach(el => {
      class_stt++;
      let tg_list_student: any;
      tg_list_student = [];
      let student_stt = -1;
      el.list_student.forEach(el22 => {
        student_stt++;
        let key_chat: string;
        key_chat = this.hs.set_key_chat(this.currentUserID, el22.user_id, el22.people_stt);
        this.set_chat_status_by_person(key_chat, class_stt, student_stt);
        let arr_tg: any;
        arr_tg = { 'people_value': el22.people_value };
        arr_tg['user_id'] = el22.user_id;
        arr_tg['people_stt'] = el22.people_stt;
        arr_tg['key_chat'] = key_chat;

        tg_list_student.push(arr_tg);
      });

      tg_list_class.push({ 'title': el.title, 'list_student': tg_list_student });
    });

    this.list_class = tg_list_class;
  }

  set_chat_status_by_person(key_chat: string, class_stt: number, student_stt: number) {
    this.itemsRef = this.db.list(key_chat, ref => ref.limitToLast(1));
    this.itemsRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    )
      .subscribe(actions => {
        actions.forEach(action => {
          let student: any;
          student = this.list_class[class_stt]['list_student'][student_stt];
          student['last_key'] = action.key;
          student['userID'] = action.userID;

          if (action['userID'] === this.currentUserID) {
            student['read'] = 0;
            const index = this.list_student_just_send.indexOf(student);
            this.list_student_just_send.splice(index, 1);
          } else {
            if (action.read === 0) {
              student['read'] = action.read;
              student['class'] = this.list_class[class_stt].title;
              if (JSON.stringify(this.list_student_just_send).indexOf(JSON.stringify(student)) === -1) {
                this.list_student_just_send.push(student);
              }
            } else {
              student['read'] = 1;
              const index = this.list_student_just_send.indexOf(student);
              this.list_student_just_send.splice(index, 1);
            }
          }
        });
      });
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { API_URL, HangSo } from '../_models/constant';

@Component({
  selector: 'app-clip-speaking-update',
  templateUrl: './clip-speaking-update.component.html',
  styleUrls: ['./clip-speaking-update.component.css']
})
export class ClipSpeakingUpdateComponent implements OnInit {
  token: string;
  update_status: any;
  loading = true;
  app_data: any;
  full_name: any;
  list_clip: any;
  clip_detail: any;
  umeta_id: any;
  post_id: any;
  act: any;
  required: any;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    public hs: HangSo
  ) { }

  ngOnInit() {
    this.token = this.hs.token2018();
    this.umeta_id = this.route.snapshot.paramMap.get('umeta_id');
    this.list_clip = [];
    this.clip_of_children(this.umeta_id);
  }

  clip_of_children(umeta_id: any) {
    const app_url = `${API_URL}/clip_of_children/umeta_id/` + umeta_id;
    this.http.get(app_url)
      .toPromise()
      .then(res => {
        console.log(res);
        this.loading = false;
        this.app_data = res;
        this.full_name = this.app_data.full_name;
        this.list_clip = this.app_data.list_clip;
        let i = -1;
        this.list_clip.forEach(el => {
          i++;
          this.list_clip[i].video_root = el.video;
          this.list_clip[i].video = this.sanitizer.bypassSecurityTrustResourceUrl(el.video);
          this.list_clip[i].video_embed = this.sanitizer.bypassSecurityTrustResourceUrl(el.video_embed);
        });
      });
  }

  onSubmit(formClipUpdate: any) {
    let url = `${API_URL}/umeta_id/`;
    url = url + this.umeta_id;
    url = url + '/update_clip_speaking';
    const body = JSON.stringify(formClipUpdate.value);
    this.update_status = 1;
    return this.http.post(url, body)
      .toPromise()
      .then(res => {
        this.update_status = 2;
        this.post_id = res;
        console.log(res);
        if (res === true) {
          this.act = '';
          setTimeout(() => {
            this.update_status = false;
            this.clip_of_children(this.umeta_id);
          }, 1000);
        }
      });
  }

  get_edit(id: number) {
    this.list_clip.forEach(el => {
      if (id === el.id) {
        console.log(el);
        this.act = 'edit';
        this.clip_detail = el;
        let tg: string;
        let res: any;
        res = this.clip_detail.date.split('/');
        if (res.length === 3) {
          tg = res[2] + '-' + res[1] + '-' + res[0];
          this.clip_detail.date = tg;
        }
      }
    });
  }

  delete(id: number) {
    this.update_status = false;
    this.loading = true;
    let parentElement: any;
    parentElement = event.srcElement.parentElement.parentElement.parentElement;
    let url: string;
    url = `${API_URL}/delete_clip_speaking`;
    this.http.post(url, {'token': this.hs.token2018() , 'clip_stt': id, 'umeta_id': this.umeta_id })
      .toPromise()
      .then(res => {
        console.log(res);
        let tg: any;
        tg = res;
        if (tg) {
          parentElement.remove();
          this.act = '';
          this.clip_of_children(this.umeta_id);
        }
        this.loading = false;
      });
  }

}

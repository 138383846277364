import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { API_URL, HangSo } from '../_models/constant';

@Component({
  selector: 'app-test-report-update',
  templateUrl: './test-report-update.component.html',
  styleUrls: ['./test-report-update.component.css']
})
export class TestReportUpdateComponent implements OnInit {
  token: string;
  update_status: any;
  loading = true;
  app_data: any;
  full_name: any;
  list_test: any;
  test_detail: any;
  umeta_id: any;
  post_id: any;
  act: any;
  required: any;

  uploading: any;
  file_: any;
  result_percent = 0;
  selectedFile: File = null;

  file_path = 'file path';

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    public hs: HangSo
  ) { }

  ngOnInit() {
    this.token = this.hs.token2018();
    this.umeta_id = this.route.snapshot.paramMap.get('umeta_id');
    this.list_test = [];
    this.loading = false;
    this.file_ = { 'url': '', 'path': '' };
    this.test_of_childen(this.umeta_id);
  }

  test_of_childen(umeta_id: any) {
    const app_url = `${API_URL}/test_of_children/umeta_id/` + umeta_id;
    this.loading = true;
    this.http.get(app_url)
      .toPromise()
      .then(res => {
        console.log(res);
        this.loading = false;
        this.app_data = res;
        this.full_name = this.app_data.full_name;
        this.list_test = this.app_data.list_test;
      });
  }

  onSubmit(formTestReportUpdate: any) {
    let url = `${API_URL}/umeta_id/`;
    url = url + this.umeta_id;
    url = url + '/update_test_report';
    const body = JSON.stringify(formTestReportUpdate.value);
    this.update_status = 1;
    return this.http.post(url, body)
      .toPromise()
      .then(res => {
        this.update_status = 2;
        this.post_id = res;
        console.log(res);
        if (res === true) {
          this.act = '';
          this.update_status = false;
          this.test_of_childen(this.umeta_id);
        }
      });
  }

  get_edit(id: number) {
    this.list_test.forEach(el => {
      if (id === el.id) {
        console.log(el);
        this.act = 'edit';
        this.test_detail = el;
        this.file_ = { 'url': this.test_detail.img, 'path': '' };
        let tg: string;
        let res: any;
        res = this.test_detail.date.split('/');
        if (res.length === 3) {
          tg = res[2] + '-' + res[1] + '-' + res[0];
          this.test_detail.date = tg;
        }
      }
    });
  }

  delete(id: number) {
    this.update_status = false;
    this.loading = true;
    let parentElement: any;
    parentElement = event.srcElement.parentElement.parentElement.parentElement;
    let url: string;
    url = `${API_URL}/delete_test_report`;
    this.http.post(url, { 'token': this.token, 'test_stt': id, 'umeta_id': this.umeta_id })
      .toPromise()
      .then(res => {
        console.log(res);
        let tg: any;
        tg = res;
        if (tg) {
          parentElement.remove();
          this.act = '';
          this.test_of_childen(this.umeta_id);
        }
        this.loading = false;
      });
  }

  onFileChanged(event: any) {
    this.selectedFile = <File>event.target.files[0];
    this.uploading = true;
    let el_img = event.srcElement.parentNode;
    this.onUpload(el_img);
    console.log(this.file_);
  }

  onUpload(el_img: any) {
    const uploadData = new FormData();
    uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
    let url = `${API_URL}/user_id/`;
    url = url + localStorage.getItem('currentUserID');
    url = url + '/upload';
    this.http.post(url, uploadData,
      {
        reportProgress: true
        , observe: 'events'
      })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.result_percent = Math.round(event.loaded / event.total * 100);
        } else if (event.type === HttpEventType.Response) {
          this.app_data = event.body;
          if (this.app_data.error === 0) {
            this.file_ = this.app_data.file_;
            console.log(this.file_);
            setTimeout(() => {
              this.uploading = false;
            }, 1000);
          }
        }
      });
  }

}

﻿import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/index';
import { LoginComponent } from './login/index';
import { AuthGuard } from './_guards/index';

import { LessonDetailComponent } from './lesson-detail/lesson-detail.component';
import { CourseComponent } from './course/course.component';
import { TestReportComponent } from './test-report/test-report.component';
import { TestReportUpdateComponent } from './test-report-update/test-report-update.component';
import { ClipSpeakingComponent } from './clip-speaking/clip-speaking.component';
import { ClipSpeakingUpdateComponent } from './clip-speaking-update/clip-speaking-update.component';

import { HomeTeacherComponent } from './home-teacher/home-teacher.component';
import { LearningPointComponent } from './learning-point/learning-point.component';
import { TeacherLessonComponent } from './teacher-lesson/teacher-lesson.component';
import { TeacherPostComponent } from './teacher-post/teacher-post.component';
import { TeacherChatComponent } from './teacher-chat/teacher-chat.component';
import { LessonResultComponent } from './lesson-result/lesson-result.component';
import { LessonResultDetailComponent } from './lesson-result-detail/lesson-result-detail.component';
import { ParentCornerComponent } from './parent-corner/parent-corner.component';
import { ParentCornerDetailComponent } from './parent-corner-detail/parent-corner-detail.component';
import { MessageComponent } from './message/message.component';
import { MessageDetailComponent } from './message-detail/message-detail.component';
import { MessageTeacherComponent } from './message-teacher/message-teacher.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'person_1/:id', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'person_2/:id', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'person_3/:id', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'person_4/:id', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    // { path: 'register', component: RegisterComponent },
    { path: 'lesson-detail/:id', component: LessonDetailComponent, canActivate: [AuthGuard] },
    { path: 'course/:id', component: CourseComponent, canActivate: [AuthGuard] },
    { path: 'message', component: MessageComponent, canActivate: [AuthGuard] },
    { path: 'message-detail/:id', component: MessageDetailComponent, canActivate: [AuthGuard] },
    { path: 'teacher-message', component: MessageTeacherComponent, canActivate: [AuthGuard] },
    { path: 'test-report/:id', component: TestReportComponent, canActivate: [AuthGuard] },
    { path: 'update-test-report/:umeta_id', component: TestReportUpdateComponent, canActivate: [AuthGuard] },
    { path: 'clip-speaking/:id', component: ClipSpeakingComponent, canActivate: [AuthGuard] },
    { path: 'update-clip-speaking/:umeta_id', component: ClipSpeakingUpdateComponent, canActivate: [AuthGuard] },
    { path: 'home-teacher', component: HomeTeacherComponent, canActivate: [AuthGuard] },
    { path: 'learning-point', component: LearningPointComponent, canActivate: [AuthGuard] },
    { path: 'teacher-lesson', component: TeacherLessonComponent, canActivate: [AuthGuard] },
    { path: 'teacher-post', component: TeacherPostComponent, canActivate: [AuthGuard] },
    { path: 'teacher-chat', component: TeacherChatComponent, canActivate: [AuthGuard] },
    { path: 'lesson-result', component: LessonResultComponent, canActivate: [AuthGuard] },
    { path: 'result-detail/:id', component: LessonResultDetailComponent, canActivate: [AuthGuard] },
    { path: 'parent-corner', component: ParentCornerComponent, canActivate: [AuthGuard] },
    { path: 'corner-detail/:id', component: ParentCornerDetailComponent, canActivate: [AuthGuard] },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);

import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { convert_to_dateYMD, count_msg_no_read } from "../_models/constant";
import { update_read_to_data } from "../_models/funcs";

@Component({
  selector: "app-test-report",
  templateUrl: "./test-report.component.html",
  styleUrls: ["./test-report.component.css"],
})
export class TestReportComponent implements OnInit {
  app_data: any;
  list_test: any;
  current_person_id: any;

  // pass to menu top component
  num_message = 0;

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit() {
    const idd = parseInt(this.route.snapshot.paramMap.get("id"), 0);
    this.current_person_id = localStorage.getItem("current_person_id");
    this.app_data = JSON.parse(localStorage.getItem("app_data"));
    this.list_test =
      this.app_data[`person_${this.current_person_id}`].test_report;
    let i = -1;
    this.list_test.forEach((el: any) => {
      i++;
      this.list_test[i]["date_sort"] = convert_to_dateYMD(el.date);
    });

    /* sort by desc date_sort*/
    this.list_test.sort(function (a: any, b: any) {
      let nameA, nameB: string;
      nameA = a.date_sort.toUpperCase();
      nameB = b.date_sort.toUpperCase();
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    });

    // the second loading
    setTimeout(() => {
      const app_data = JSON.parse(localStorage.getItem("app_data"));
      const person_stt = parseInt(localStorage.getItem("current_person_id"), 0);
      this.num_message = count_msg_no_read(app_data, person_stt);
    }, 500);
  }

  onClickItem(stt: string) {
    const user_id = localStorage.getItem("currentUserID");
    const person_stt = parseInt(localStorage.getItem("current_person_id"), 0);

    const check_read = this.list_test.find(
      (el: any) => el.id == stt && el.read == 1
    );
    if (!check_read) {
      update_read_to_data(this.http, {
        type: "test_report",
        user_id,
        person_stt,
        id: stt,
      });
    }

    this.list_test.forEach((el: any) => {
      if (el.id == stt) {
        el.read = 1;
      }
    });

    this.num_message = count_msg_no_read(this.app_data, person_stt);
  }
}

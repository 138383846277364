import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

import { API_URL, HangSo } from '../_models/constant';

@Component({
  selector: 'app-learning-point',
  templateUrl: './learning-point.component.html',
  styleUrls: ['./learning-point.component.css']
})
export class LearningPointComponent implements OnInit {
  token: string;
  loading = true;
  update_status: any;
  app_data: any;
  list_student: any;
  current_class_id: any;
  current_class_name: any;
  number_lesson: number;
  finish_lesson_number: any;
  list_lesson: any;
  current_lesson_id: any;
  current_lesson_name: any;

  constructor(
    private http: HttpClient,
    public hs: HangSo
  ) { }

  ngOnInit() {
    this.token = this.hs.token2018();
    this.loading = true;
    this.current_class_id = localStorage.getItem('current_class_id');
    this.app_data = JSON.parse(localStorage.getItem('app_data'));
    this.app_data.list_class.forEach(el => {
      if (el.id == this.current_class_id) {
        this.number_lesson = el.number_lesson;
        this.finish_lesson_number = el.finish_lesson_number;
        this.current_class_name = el.title;
      }
    });

    let url = `${API_URL}/`;
    url = url + 'get_lesson/' + this.current_class_id;
    this.http.get(url)
    .toPromise()
    .then(res => {
      // console.log(res);
      let data_tg: any;
      data_tg = res;
      this.list_lesson = data_tg.list;
      this.current_lesson_name = 'Buổi ' + data_tg.current_lesson.lesson;
      this.current_lesson_id = data_tg.current_lesson.lesson;
      this.get_list_student(this.current_class_id, this.current_lesson_id);
    });
  }

  change_lesson($event: any) {
    const lesson = $event.target.dataset.id;
    this.current_lesson_id = lesson;
    this.current_lesson_name = $event.target.innerHTML;
    this.list_student = false;
    this.get_list_student(this.current_class_id, this.current_lesson_id);
  }

  get_list_student(class_: any, lesson: any) {
    this.loading = true;
    let url = `${API_URL}/`;
    url = url + 'class/' + class_ + '/lesson/' + lesson;
    this.http.get(url)
      .toPromise()
      .then(res => {
        if (res[0] === undefined) {
          this.app_data.list_class.forEach(el => {
            if (el.id == class_) {
              this.list_student = el.list_student;
            }
          });
        } else {
          this.list_student = res;
        }
        // console.log(this.list_student);
        this.loading = false;
      });
  }

  onSubmit(formLearning: any) {
    let url = `${API_URL}/user_id/`;
    url = url + localStorage.getItem('currentUserID');
    url = url + '/learning_point';
    const body = JSON.stringify(formLearning.value);
    this.update_status = 1;
    return this.http.post(url, body)
      .toPromise()
      .then(res => {
        this.update_status = 2;
        setTimeout(() => {
          this.update_status = 3;
        }, 1500);

        // res.json();
        console.log(res);
      });
  }

}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { count_msg_no_read } from "../_models/constant";

@Component({
  selector: "app-parent-corner-detail",
  templateUrl: "./parent-corner-detail.component.html",
  styleUrls: ["./parent-corner-detail.component.css"],
})
export class ParentCornerDetailComponent implements OnInit {
  loading = true;
  list_news: any;
  news_detail: any;

  // pass to menu top component
  num_message = 0;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const idd = this.route.snapshot.paramMap.get("id");
    this.loading = false;
    this.list_news = JSON.parse(localStorage.getItem("list_parent_corner"));
    this.list_news.forEach((el: any) => {
      if (el.id == idd) {
        this.news_detail = el;
      }
    });

    // the second loading
    setTimeout(() => {
      const app_data = JSON.parse(localStorage.getItem("app_data"));
      const person_stt = parseInt(localStorage.getItem("current_person_id"), 0);
      this.num_message = count_msg_no_read(app_data, person_stt);
    }, 500);
  }
}

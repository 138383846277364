import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { User } from '../_models/index';
import { UserService } from '../_services/index';

import 'rxjs/add/operator/toPromise';
import { ArrayType } from '@angular/compiler/src/output/output_ast';
import { API_URL } from '../_models/constant';

@Component({
    moduleId: module.id,
    templateUrl: 'home-teacher.component.html'
})
export class HomeTeacherComponent implements OnInit {
    users: User[] = [];
    arr_tg: any;
    app_data: any;
    list_class: any;
    list_news: any;
    loading = true;
    current_class_id: any;
    finish_learning_point: any;

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private http: HttpClient
    ) { }

    ngOnInit() {
        const app_url = `${API_URL}/user_id/` + localStorage.getItem('currentUserID');
        if (localStorage.getItem('app_data') === null) {
            this.http.get(app_url)
                .toPromise()
                .then(res => {
                    localStorage.setItem('app_data', JSON.stringify(res));
                    this.loading = false;
                    this.app_data = res;
                    this.list_class = this.app_data.list_class;
                    this.list_news = this.app_data.list_news;

                    this.current_class_id = this.list_class[0].id;
                    this.finish_learning_point = this.list_class[0].finish_learning_point;
                    localStorage.setItem('current_class_id', this.current_class_id);
                    localStorage.setItem('current_number_lesson', this.list_class[0].number_lesson);
                    localStorage.setItem('finish_learning_point', this.finish_learning_point);
                });
        } else {
            this.app_data = JSON.parse(localStorage.getItem('app_data'));
            this.loading = false;
            this.list_class = this.app_data.list_class;
            this.list_news = this.app_data.list_news;

            this.current_class_id = localStorage.getItem('current_class_id');
            this.finish_learning_point = localStorage.getItem('finish_learning_point');

            // sap xep lai mang, cho item hien tai len dau
            let arr_tg: any;
            arr_tg = [];
            this.list_class.forEach(el => {
                if (this.current_class_id == el.id) {
                    el.class_css = 'active';
                    arr_tg.push(el);
                }
            });
            this.list_class.forEach(el => {
                if (this.current_class_id != el.id) {
                    el.class_css = '';
                    arr_tg.push(el);
                }
            });
            this.list_class = arr_tg;

        }
    }

    finish_learning_p() {
        this.finish_learning_point = event.srcElement.getAttribute('data-finish_learning_point');
        localStorage.setItem('finish_learning_point', this.finish_learning_point);
    }
}

export const DOMAIN_API = "https://nemoschool.edu.vn/wp-json";

export const API_URL = `${DOMAIN_API}/my_app/v2`;
export const API_CONTEXT_URL = `${DOMAIN_API}/wp/v2/users/me?context=edit`;
export class HangSo {
  ArrTrangThaiHoc = [
    { key: 1, value: "Học" },
    { key: 2, value: "Đi muộn" },
    { key: 3, value: "Nghỉ có phép" },
    { key: 4, value: "Nghỉ không phép" },
  ];
  x() {
    return 2018;
  }
  set_key_chat(teacherID: number, subscriberID: number, people_stt: number) {
    /*
        t: teacher
        s: subscriber(phụ huynh)
        p1: people_1(con 1)
        */
    return "t" + teacherID + "_s" + subscriberID + "_p" + people_stt;
  }

  token2018() {
    let x, y: string;
    x = localStorage.getItem("jwt_authorization_api");
    if (x === undefined) {
      return "";
    } else {
      y = x.replace(" ", "_NemoSchool_");
      return y;
    }
  }
}

export function convert_to_dateYMD(date_dd_mm_YY: string) {
  let res = [];
  res = date_dd_mm_YY.split("/");
  return res[2] + "/" + res[1] + "/" + res[0];
}

export function count_msg_no_read(_app_data: any, _person_id = 1) {
  let num = 0;

  if (!_app_data) {
    return 0;
  }

  const list_news = _app_data[`list_news_person_${_person_id}`];
  const list_lesson = _app_data[`person_${_person_id}`].list_lesson;
  const list_test_report = _app_data[`person_${_person_id}`].test_report;
  const list_clip_speaking = _app_data[`person_${_person_id}`].clip_speaking;

  if (list_news != null) {
    for (let i = 0; i < list_news.length; i++) {
      if (list_news[i].read == 0) {
        num++;
      }
    }
  }

  if (list_lesson != null) {
    for (let i = 0; i < list_lesson.length; i++) {
      if (list_lesson[i].read == 0) {
        num++;
      }
    }
  }

  if (list_test_report != null) {
    for (let i = 0; i < list_test_report.length; i++) {
      if (list_test_report[i].read == 0) {
        num++;
      }
    }
  }

  if (list_clip_speaking != null) {
    for (let i = 0; i < list_clip_speaking.length; i++) {
      if (list_clip_speaking[i].read == 0) {
        num++;
      }
    }
  }

  return num;
}

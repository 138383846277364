import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lesson-result-detail',
  templateUrl: './lesson-result-detail.component.html',
  styleUrls: ['./lesson-result-detail.component.css']
})
export class LessonResultDetailComponent implements OnInit {
  loading = true;
  app_data: any;
  list_news: any;
  news_detail: any;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const idd = this.route.snapshot.paramMap.get('id');
    this.loading = false;
    console.log(idd);
  }

}

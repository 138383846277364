import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { API_URL, count_msg_no_read } from "../_models/constant";
import { update_read_to_data } from "../_models/funcs";

@Component({
  selector: "app-message-detail",
  templateUrl: "./message-detail.component.html",
  styleUrls: ["./message-detail.component.css"],
})
export class MessageDetailComponent implements OnInit {
  loading = true;
  message_detail: any;
  is_gallery = false;

  // pass to menu top component
  num_message = 0;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const idd = parseInt(this.route.snapshot.paramMap.get("id"), 0);

    const user_id = localStorage.getItem("currentUserID");
    const person_stt = localStorage.getItem("current_person_id");
    const app_data = JSON.parse(localStorage.getItem("app_data"));
    const list_message = app_data[`list_news_person_${person_stt}`];

    this.loading = true;

    const url = `${API_URL}/get_message/` + idd;
    this.message_detail = {};

    this.http
      .post(url, { user_id })
      .toPromise()
      .then((res) => {
        this.message_detail = res;
        this.message_detail.content = this.sanitizer.bypassSecurityTrustHtml(
          this.message_detail.content
        );
        if (this.message_detail.gallery[0] !== undefined) {
          this.is_gallery = true;
        }
        this.loading = false;
      });

    const check_read = list_message.find(
      (el: any) => el.id == idd && el.read == 1
    );
    if (!check_read) {
      update_read_to_data(this.http, {
        type: "message",
        id: idd,
        user_id,
        person_stt,
      });
    }

    // the second loading
    setTimeout(() => {
      const app_data = JSON.parse(localStorage.getItem("app_data"));
      const person_stt = parseInt(localStorage.getItem("current_person_id"), 0);
      this.num_message = count_msg_no_read(app_data, person_stt);
    }, 500);
  }
}

import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { API_URL, count_msg_no_read } from "../_models/constant";

@Component({
  selector: "app-course",
  templateUrl: "./course.component.html",
  styleUrls: ["./course.component.css"],
})
export class CourseComponent implements OnInit {
  loading = true;
  app_data: any;
  noidung: any;
  title: any;

  // pass to menu top component
  num_message = 0;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.loading = true;
    const idd = parseInt(this.route.snapshot.paramMap.get("id"), 0);

    const url = `${API_URL}/get_course/` + idd;
    this.title = "";
    this.noidung = "";
    this.http
      .get(url)
      .toPromise()
      .then((res) => {
        this.title = res["title"];
        this.noidung = this.sanitizer.bypassSecurityTrustHtml(res["noidung"]);

        this.loading = false;
      });

    // the second loading
    setTimeout(() => {
      const app_data = JSON.parse(localStorage.getItem("app_data"));
      const person_stt = parseInt(localStorage.getItem("current_person_id"), 0);
      this.num_message = count_msg_no_read(app_data, person_stt);
    }, 500);
  }
}

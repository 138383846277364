import { API_URL } from "./constant";

export class Funcs {}

function update_local_read(_data = {}) {
  const app_data = JSON.parse(localStorage.getItem("app_data") || "{}");
  switch (_data["type"]) {
    case "message":
      app_data[`list_news_person_${_data["person_stt"]}`].forEach((el: any) => {
        if (el.id == _data["id"]) {
          el.read = 1;
        }
      });
      break;
    case "test_report":
      app_data[`person_${_data["person_stt"]}`].test_report.forEach(
        (el: any) => {
          if (el.id == _data["id"]) {
            el.read = 1;
          }
        }
      );
      break;
    case "clip_speaking":
      app_data[`person_${_data["person_stt"]}`].clip_speaking.forEach(
        (el: any) => {
          if (el.id == _data["id"]) {
            el.read = 1;
          }
        }
      );
      break;
    case "lesson":
      app_data[`person_${_data["person_stt"]}`].list_lesson.forEach(
        (el: any) => {
          if (el.app_teacher_lesson_ID == _data["id"]) {
            el.read = 1;
          }
        }
      );
      break;
  }

  localStorage.setItem("app_data", JSON.stringify(app_data));
}

export function update_read_to_data(http: any, _data = {}) {
  update_local_read(_data);

  const url = `${API_URL}/app_update_read`;
  http
    .post(url, _data)
    .toPromise()
    .then((res: any) => {
      console.log(res);
      return res;
    });
}

import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { convert_to_dateYMD, count_msg_no_read } from "../_models/constant";
import { update_read_to_data } from "../_models/funcs";

@Component({
  selector: "app-clip-speaking",
  templateUrl: "./clip-speaking.component.html",
  styleUrls: ["./clip-speaking.component.css"],
})
export class ClipSpeakingComponent implements OnInit {
  app_data: any;
  list_clip: any;
  current_person_id: any;

  // pass to menu top component
  num_message = 0;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {}

  ngOnInit() {
    const idd = parseInt(this.route.snapshot.paramMap.get("id"), 0);
    this.current_person_id = localStorage.getItem("current_person_id");
    this.app_data = JSON.parse(localStorage.getItem("app_data"));
    this.list_clip =
      this.app_data[`person_${this.current_person_id}`].clip_speaking;
    let i = -1;
    this.list_clip.forEach((el: any) => {
      i++;
      this.list_clip[i]["date_sort"] = convert_to_dateYMD(el.date);
      this.list_clip[i].video_target = el.video;
      this.list_clip[i].video = this.sanitizer.bypassSecurityTrustResourceUrl(
        el.video
      );
    });
    /* sort by desc date_sort*/
    this.list_clip.sort(function (a: any, b: any) {
      let nameA, nameB: string;
      nameA = a.date_sort.toUpperCase();
      nameB = b.date_sort.toUpperCase();
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    });

    // the second loading
    setTimeout(() => {
      const person_stt = parseInt(localStorage.getItem("current_person_id"), 0);
      this.num_message = count_msg_no_read(this.app_data, person_stt);
    }, 500);
  }

  onClickItem(stt: string) {
    const user_id = localStorage.getItem("currentUserID");
    const person_stt = parseInt(localStorage.getItem("current_person_id"), 0);

    const check_read = this.list_clip.find(
      (el: any) => el.id == stt && el.read == 1
    );
    if (!check_read) {
      update_read_to_data(this.http, {
        type: "clip_speaking",
        user_id,
        person_stt,
        id: stt,
      });
    }

    this.list_clip.forEach((el: any) => {
      if (el.id == stt) {
        el.read = 1;
      }
    });

    this.num_message = count_msg_no_read(this.app_data, person_stt);
  }
}

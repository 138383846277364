// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  firebase : {
    apiKey: 'AIzaSyD44mr66_X-q5GaCbebcoQS26ioaARYP04',
    authDomain: 'api-project-771912736290.firebaseapp.com',
    databaseURL: 'https://api-project-771912736290.firebaseio.com',
    projectId: 'api-project-771912736290',
    storageBucket: 'api-project-771912736290.appspot.com',
    messagingSenderId: '771912736290'
  }
};

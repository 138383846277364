﻿import { Component, OnInit, Inject } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { HttpClient } from "@angular/common/http";

import {
  AlertService,
  AuthenticationService,
  UserService,
} from "../_services/index";

import "rxjs/add/operator/toPromise";

import { DOCUMENT } from "@angular/common";

import { API_CONTEXT_URL } from "../_models/constant";

@Component({
  moduleId: module.id,
  templateUrl: "login.component.html",
})
export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;
  person_info_login: any;
  error_login = "";

  window: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private userService: UserService,
    private http: HttpClient,

    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  ngOnInit() {
    this.authenticationService.logout();

    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  login() {
    let jwt_authorization_api = this.model.username + ":" + this.model.password;
    jwt_authorization_api = "Basic " + btoa(jwt_authorization_api);
    this.http
      .get<String>(API_CONTEXT_URL, {
        headers: {
          authorization: jwt_authorization_api,
        },
      })
      .toPromise()
      .then((res) => {
        this.person_info_login = res;
        if (this.person_info_login.id > 0) {
          // nap lai tat ca
          localStorage.clear();
          localStorage.setItem("currentUserID", this.person_info_login.id);
          localStorage.setItem(
            "currentUserRoles",
            this.person_info_login.roles
          );
          localStorage.setItem("jwt_authorization_api", jwt_authorization_api);
          if (this.person_info_login.roles == "subscriber") {
            localStorage.setItem("current_person_id", "1");
          }
          // dang ky tu dong 1 tai khoan
          this.userService.create(this.model).subscribe(
            (data) => {
              console.log("Dang ky tu dong thanh cong");
            },
            (error) => {
              console.log("Dang ky tu dong that bai");
            }
          );

          // dang nhap luon
          this.loading = true;
          this.authenticationService
            .login(this.model.username, this.model.password)
            .subscribe(
              (data) => {
                if (this.window.ReactNativeWebView) {
                  const user = {
                    username: this.model.username,
                    screen: "login",
                  };
                  this.window.ReactNativeWebView.postMessage(
                    JSON.stringify(user)
                  );
                  console.log(`Login page component, user: ${user}`);
                }

                if (this.person_info_login.roles == "subscriber") {
                  // ve giao dien phu huynh
                  this.router.navigate([this.returnUrl]);
                } else {
                  // ve giao dien giao vien
                  // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home-teacher';
                  this.returnUrl = "/home-teacher";
                  this.router.navigate([this.returnUrl]);
                }
              },
              (error) => {
                this.alertService.error(error);
                this.loading = false;
              }
            );
        }
      })
      .catch((err) => {
        this.loading = true;
        this.authenticationService.login("", "").subscribe(
          (data) => {
            this.router.navigate([this.returnUrl]);
          },
          (error) => {
            this.alertService.error(error);
            this.loading = false;
            this.error_login = error;
          }
        );
      });
  }
}

﻿import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'app',
    templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit {
    currentUserRoles: string;
    constructor() {}

    ngOnInit() {
        this.currentUserRoles = localStorage.getItem('currentUserRoles');
    }
}



import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { convert_to_dateYMD } from "../_models/constant";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.css"],
})
export class MessageComponent implements OnInit {
  loading = true;
  current_person_id: any;
  app_data: any;
  person: any;
  list_lesson: any;
  list_news: any;
  list_test_report: any;
  list_clip_speaking: any;
  list_message: any;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.loading = true;
    this.current_person_id = localStorage.getItem("current_person_id");

    this.app_data = JSON.parse(localStorage.getItem("app_data"));
    this.set_current_person();

    this.list_message = [];
    let i: number;

    /* list news*/
    this.list_news.forEach((el: any) => {
      let arr_tg: any;
      arr_tg = { date: el.date };
      arr_tg["date_sort"] = convert_to_dateYMD(el.date);
      arr_tg["link"] = "/message-detail/" + el.id;
      arr_tg["class"] = el.read == 1 ? "read" : "no-read";
      arr_tg["title"] = el.title;
      this.list_message.push(arr_tg);
    });

    /* list lesson*/
    this.list_lesson.forEach((el: any) => {
      let arr_tg: any;
      arr_tg = { date: el.date };
      arr_tg["date_sort"] = convert_to_dateYMD(el.date);
      arr_tg["link"] = "/lesson-detail/" + el.app_teacher_lesson_ID;
      arr_tg["class"] = el.read == 1 ? "read" : "no-read";
      arr_tg["title"] = "B" + el.lesson + " | " + el.date + " | " + el.title;
      this.list_message.push(arr_tg);
    });

    /* test report*/
    i = 0;
    this.list_test_report.forEach((el: any) => {
      i++;
      let arr_tg: any;
      arr_tg = { date: el.date };
      arr_tg["date_sort"] = convert_to_dateYMD(el.date);
      arr_tg["link"] = "/test-report/" + i;
      arr_tg["class"] = el.read == 1 ? "read" : "no-read";
      arr_tg["title"] = "Test Report: " + el.date + " | " + el.name;
      this.list_message.push(arr_tg);
    });

    /* clip speaking */
    i = 0;
    this.list_clip_speaking.forEach((el: any) => {
      i++;
      let arr_tg: any;
      arr_tg = { date: el.date };
      arr_tg["date_sort"] = convert_to_dateYMD(el.date);
      arr_tg["link"] = "/clip-speaking/" + i;
      arr_tg["class"] = el.read == 1 ? "read" : "no-read";
      arr_tg["title"] = "Clip Speaking: " + el.date + " | " + el.name;
      this.list_message.push(arr_tg);
    });

    /* sort by desc date_sort*/
    this.list_message.sort(function (a: any, b: any) {
      let nameA, nameB: string;
      nameA = a.date_sort.toUpperCase();
      nameB = b.date_sort.toUpperCase();
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    });
  }

  set_current_person() {
    const person_stt = this.current_person_id;
    this.person = this.app_data[`person_${person_stt}`];
    this.list_lesson = this.app_data[`person_${person_stt}`].list_lesson;
    this.list_news = this.app_data[`list_news_person_${person_stt}`];
    this.list_test_report = this.app_data[`person_${person_stt}`].test_report;
    this.list_clip_speaking =
      this.app_data[`person_${person_stt}`].clip_speaking;
  }
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { HangSo } from '../_models/constant';

import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PushModel } from '../_models/pushmodel';

@Component({
  selector: 'app-menu-bottom-teacher',
  templateUrl: 'menu-bottom-teacher.component.html'
})
export class MenuBottomTeacherComponent implements OnInit {
  loading: any;
  token: string;
  currentUserID: any;
  teacherID: any;
  currentPersonChat: any;
  app_data: any;
  person: any;
  currentUserRoles: string;
  list_current_chat: any;
  show_form = false;
  show_chat = true;
  key_chat = '';
  header_chat = '';
  message_chat = '';
  id_remove_chat = '';
  list_last_key_chat: any;

  itemsRef_online: AngularFireList<any>;
  items_online: Observable<any[]>;
  just_receive: any;
  count_just_receive = 0;

  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;
  db: AngularFireDatabase;
  constructor(
    private http: HttpClient,
    public hs: HangSo,
    db_const: AngularFireDatabase
  ) {
    this.db = db_const;
  }

  ngOnInit() {
    this.loading = false;
    this.token = this.hs.token2018();
    this.timeout();
    this.list_current_chat = [];
    this.list_last_key_chat = [];

    let current_chat: any;
    current_chat = localStorage.getItem('list_current_chat');
    if (current_chat !== null) {
      this.list_current_chat = JSON.parse(current_chat);
    }
  }

  timeout() {
    let arr_data: any;
    arr_data = localStorage.getItem('app_data');
    if (arr_data !== null) {
        this.currentUserID = parseInt(localStorage.getItem('currentUserID'), 0);
        this.currentPersonChat = localStorage.getItem('current_person_id');

        this.currentUserRoles = localStorage.getItem('currentUserRoles');
        if (this.currentUserRoles == null) {
          this.currentUserRoles = 'subscriber';
        }
        this.app_data = JSON.parse(arr_data);

        this.header_chat = '';

        this.count_just_send_chat();
    } else {
      setTimeout(() => {
        this.timeout();
      }, 1000);
    }
  }

  findObjectByKey(array: any, key: string, value: string) {
    for (let i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
            return true;
        }
    }
    return null;
 }

 set_list_new_key_chat(key_chat: string, last_key_chat: string, userID: string) {
  let tg: any;
  tg = {'key_chat': key_chat, 'last_key_chat': last_key_chat, 'userID': userID};
  if ( !this.findObjectByKey(this.list_last_key_chat, 'key_chat', key_chat) ) {
    this.list_last_key_chat.push(tg);
  } else {
    const index = this.list_last_key_chat.indexOf(tg);
    this.list_last_key_chat.splice(index, 1);
    this.list_last_key_chat.push(tg);
  }
 }

  count_just_send_chat() {
    this.just_receive = [];
    if (this.app_data.list_class === undefined) { return; }

    this.app_data.list_class.forEach(el => {
     el.list_student.forEach(el22 => {
       let key_chat: string;
       key_chat = this.hs.set_key_chat(this.currentUserID, el22.user_id, el22.people_stt);

      this.itemsRef_online = this.db.list(key_chat, ref => ref.limitToLast(1));
      this.itemsRef_online.snapshotChanges().pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      )
      .subscribe(actions => {
        actions.forEach(action => {
          this.set_list_new_key_chat(key_chat, action.key, action.userID);

          let tg_send: any;
          tg_send = {'userID' : action['userID']};
          if (action['userID'] === this.currentUserID) {
            console.log(111);
            const index = this.just_receive.indexOf(tg_send);
            this.just_receive.splice(index, 1);
          } else {
            if (action.read === 0) {
              console.log(222);
              if (JSON.stringify(this.just_receive).indexOf(JSON.stringify(tg_send)) === -1) {
                this.just_receive.push(tg_send);
                console.log(this.just_receive);
              }
            } else {
              console.log(333);
              const index = this.just_receive.indexOf(tg_send);
              this.just_receive.splice(index, 1);
            }
          }
          this.count_just_receive = this.just_receive.length;
        });
      });
     });
   });
  }

  show_hid_chat(key_chat: any, header_chat: any) {
    if (key_chat === '') {
      this.show_form = !this.show_form;
    } else {
      this.show_form = true;
      this.header_chat = header_chat;
      this.key_chat = key_chat;
      this.set_chat_with_person(key_chat, 100);
    }
  }

  update_to_db(key_chat: string) {
    let myNodelist: any;
    myNodelist = document.getElementById('last_key_chat').querySelectorAll('li');
    let i: number;
    let key_update_db = '', userID = '';
    for (i = 0; i < myNodelist.length; i++) {
      if ( myNodelist[i].id === key_chat ) {
        let arr: any;
        arr = myNodelist[i].innerHTML.split('(|)');
        key_update_db = arr[1];
        userID = arr[0];
      }
    }
    if ( userID !== this.currentUserID && key_chat !== '' && key_update_db !== '') {
      let values_update: any;
      values_update = {
        read: 1
      };
      this.itemsRef.update(key_update_db, values_update);
    }
  }

  remove_people_chat() {
    this.show_form = false;
    let x: any;
    x = {'key_chat': this.key_chat, 'header_chat': this.header_chat};
    const index = this.list_current_chat.indexOf(x);
    this.list_current_chat.splice(index, 1);
    localStorage.setItem('list_current_chat', JSON.stringify(this.list_current_chat));
  }

  send_chat(e: any, click: number) {
    if (click === 1) { } else {
      if (e.keyCode !== 13) { return; }
      e.preventDefault();
    }

    let message: string;
    message = this.message_chat.replace('\n', '');
    if (message !== '') {
      this.itemsRef.push({
        msg: message,
        date: Date.now(),
        userID: parseInt(this.currentUserID, 0),
        read: 0,
      });
      this.message_chat = "";

      this.scroll_chat();

      // Begin: push notification to parent:
      const last_key_chat = this.list_last_key_chat || [];
      const last_key = last_key_chat[last_key_chat.length - 1]["key_chat"];
      const userID_receiver = parseInt(last_key.split("_s")[1], 0);
      PushModel.send_chat_userid(message, userID_receiver)
        .then((response: any) => {
          console.log(response.data);
        })
        .catch((error: any) => {
          console.log(error);
        });
      // End: push notification to parent:
    }
  }

  scroll_chat() {
    let element: any;
    element = document.getElementById('chat_outer');
    element.scrollTop = element.scrollHeight;
  }

  set_chat_with_person(key_chat: string, limit = 100) {
    this.itemsRef = this.db.list(key_chat, ref => ref.limitToLast(limit));
    this.items = this.itemsRef.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
  }

}

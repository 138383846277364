import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpEventType } from "@angular/common/http";
import "rxjs/add/operator/toPromise";
import { API_URL, HangSo } from "../_models/constant";

import { AngularFireDatabase, AngularFireList } from "@angular/fire/database";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PushModel } from "../_models/pushmodel";

@Component({
  selector: "app-menu-bottom",
  templateUrl: "menu-bottom.component.html",
})
export class MenuBottomComponent implements OnInit {
  loading: any;
  token: string;
  currentUserID: any;
  teacherID: any;
  currentPersonChat: any;
  app_data: any;
  person: any;
  currentUserRoles: string;
  list_person_short: any;
  show_form = false;
  show_chat = true;
  header_chat = "";
  message_chat = "";
  lastest_key = "";
  path_img: any;

  uploading = false;
  selectedFile: File = null;
  result_percent = 0;
  files_: any;

  itemsRef_update: AngularFireList<any>;

  itemsRef_online: AngularFireList<any>;
  items_online: Observable<any[]>;

  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;
  db: AngularFireDatabase;

  constructor(
    public hs: HangSo,
    db_const: AngularFireDatabase,
    private http: HttpClient
  ) {
    this.db = db_const;
  }

  send_chat(e: any, click: number) {
    if (click === 1) {
    } else {
      if (e.keyCode !== 13) {
        return;
      }
      e.preventDefault();
    }

    let message: string;
    message = this.message_chat.replace("\n", "");
    if (message !== "") {
      this.itemsRef.push({
        msg: message,
        date: Date.now(),
        userID: parseInt(this.currentUserID, 0),
        read: 0,
      });
      this.message_chat = "";
      // this.set_online_chat();
      this.scroll_chat();

      // push notification to teacher:
      PushModel.send_chat_username(message, this.person.teacher_phone)
        .then((response: any) => {
          console.log(response.data);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  scroll_chat() {
    let element: any;
    element = document.getElementById("chat_outer");
    element.scrollTop = element.scrollHeight;
  }

  show_hid_chat(teacherID: any, PersonChat: any) {
    if (teacherID === 0 || PersonChat === 0) {
      this.show_form = !this.show_form;
    } else {
      this.show_form = true;
      this.set_chat_by_person(teacherID, this.currentUserID, PersonChat, 100);
      this.header_chat = this.set_header_chat(PersonChat);
    }
  }

  set_new_msg(PersonChat: number, key_update_db: string, userID: string) {
    let tg_person_short: any;
    tg_person_short = [];
    if (this.list_person_short === undefined) {
      return;
    }
    this.list_person_short.forEach((el: any) => {
      let arr_tg: any;
      arr_tg = el;
      if (PersonChat == el.id) {
        arr_tg["new_msg"] = 0;
        this.update_to_db(key_update_db, userID);
      }
      tg_person_short.push(arr_tg);
    });
    this.list_person_short = tg_person_short;
  }

  update_to_db(key_update_db: string, userID: string) {
    if (userID != this.currentUserID) {
      let values_update: any;
      values_update = {
        read: 1,
      };
      if (key_update_db !== undefined) {
        this.itemsRef.update(key_update_db, values_update);
      }
    }
  }

  ngOnInit() {
    this.loading = false;
    this.token = this.hs.token2018();
    this.timeout();
  }

  timeout() {
    let arr_data: any;
    arr_data = localStorage.getItem("app_data");
    if (arr_data !== null) {
      this.currentUserID = localStorage.getItem("currentUserID");
      this.currentPersonChat = localStorage.getItem("current_person_id");

      this.currentUserRoles = localStorage.getItem("currentUserRoles");
      if (this.currentUserRoles == null) {
        this.currentUserRoles = "subscriber";
      }
      this.app_data = JSON.parse(arr_data);
      this.list_person_short = this.app_data.list_person_short;
      this.set_online_chat();
    } else {
      setTimeout(() => {
        this.timeout();
      }, 500);
    }
  }

  set_online_chat() {
    /* thong bao khi co tin nhan moi */
    let tg_l_person_short: any;
    tg_l_person_short = [];
    this.list_person_short.forEach((el: any) => {
      let arr_tg: any;
      arr_tg = { id: el.id };
      arr_tg["image"] = el.image;
      arr_tg["name"] = el.name;
      arr_tg["teacherID"] = el.teacherID;
      let key_chat: string;
      key_chat = this.hs.set_key_chat(el.teacherID, this.currentUserID, el.id);

      this.itemsRef_online = this.db.list(key_chat, (ref) =>
        ref.limitToLast(1)
      );
      this.itemsRef_online
        .snapshotChanges()
        .pipe(
          map((changes) =>
            changes.map((c) => ({ key: c.payload.key, ...c.payload.val() }))
          )
        )
        .subscribe((actions) => {
          actions.forEach((action) => {
            arr_tg["last_key"] = action.key;
            arr_tg["userID"] = action.userID;

            console.log(action["userID"] + "vs" + this.currentUserID);
            if (action["userID"] == this.currentUserID) {
              console.log("lan 1");
              arr_tg["new_msg"] = 0;
            } else {
              if (action.read === 0) {
                console.log("lan 2");
                arr_tg["new_msg"] = 1;
              } else {
                console.log("lan 3");
                arr_tg["new_msg"] = 0;
              }
            }
          });
        });

      tg_l_person_short.push(arr_tg);
    });

    this.list_person_short = tg_l_person_short;
  }

  set_chat_by_person(
    teacherID: number,
    userID: number,
    PersonChat: number,
    limit = 100
  ) {
    let key_chat: string;
    key_chat = this.hs.set_key_chat(teacherID, userID, PersonChat);
    this.itemsRef = this.db.list(key_chat, (ref) => ref.limitToLast(limit));
    this.items = this.itemsRef
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
    /*
    this.items.subscribe(actions => {
      actions.forEach(action => {
        this.lastest_key = action.key;
        console.log(action);
      });
    });
    */
  }

  set_teacherID() {
    if (typeof this.person !== "undefined") {
      this.teacherID = this.person.teacherID;
    }
  }

  set_header_chat(PersonChat: number) {
    switch (PersonChat) {
      case 1:
        this.person = this.app_data.person_1;
        break;
      case 2:
        this.person = this.app_data.person_2;
        break;
      case 3:
        this.person = this.app_data.person_3;
        break;
      case 4:
        this.person = this.app_data.person_4;
        break;
    }
    if (typeof this.person !== "undefined") {
      return (
        "GV " + this.person.teacher + " <strong>vs</strong> " + this.person.name
      );
    }
  }

  onFileChanged(event: any, person_stt: number) {
    for (let index = 0; index < event.target.files.length; index++) {
      this.selectedFile = <File>event.target.files[index];
      this.uploading = true;
      this.onUpload(person_stt);
    }
  }

  onUpload(person_stt: number) {
    const uploadData = new FormData();
    uploadData.append("myFile", this.selectedFile, this.selectedFile.name);
    uploadData.append("userID", this.currentUserID);
    uploadData.append("person_stt", person_stt.toString());
    console.log("data upload: ", uploadData);

    this.http
      .post(`${API_URL}/save-avatar`, uploadData, {
        reportProgress: true,
        observe: "events",
      })
      .subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.result_percent = Math.round((event.loaded / event.total) * 100);
          console.log("Upload Progress: " + this.result_percent + "%");
        } else if (event.type === HttpEventType.Response) {
          const result: any = event.body;
          console.log("result: ", result);
          const arr = JSON.parse(result);
          if (arr["thumbnail_url"]) {
            this.update_child_avatar(person_stt, arr["thumbnail_url"]);
          }

          if (this.app_data.error === 0) {
            this.files_.push(this.app_data.file_);
          }
        }
        this.uploading = false;
      });
  }

  update_child_avatar(person_stt: number, image_url: string) {
    const list_person = this.app_data.list_person_short;
    list_person.forEach((el: any) => {
      if (el.id == person_stt) {
        el.image = image_url;
      }
    });

    this.list_person_short = list_person;
    this.app_data.list_person_short = list_person;

    localStorage.setItem("app_data", JSON.stringify(this.app_data));
  }
}

import axios from "axios";
import { API_URL } from "./constant";
export class PushModel {
  static configHeader = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  static send_chat_username(message: string, username_receiver: string) {
    const data_send = {
      username_receiver: username_receiver,
      message: message,
    };
    console.log("data_send: ", data_send);
    return axios.post(
      `${API_URL}/push-chat-username`,
      data_send,
      this.configHeader
    );
  }

  static send_chat_userid(message: string, userid_receiver: number) {
    const data_send = {
      userid_receiver: userid_receiver,
      message: message,
    };
    console.log("data_send: ", data_send);
    return axios.post(
      `${API_URL}/push-chat-userid`,
      data_send,
      this.configHeader
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../_models/constant';

@Component({
  selector: 'app-message-teacher',
  templateUrl: './message-teacher.component.html',
  styleUrls: ['./message-teacher.component.css']
})
export class MessageTeacherComponent implements OnInit {
  loading = true;
  list_news: any;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.loading = true;
    const url = `${API_URL}/get_message/0`;
    this.http.post(url, { 'user_id': localStorage.getItem('currentUserID'), 'user_type': 'teacher' })
    .toPromise()
    .then(res => {
      console.log(res);
      this.list_news = res;
      this.loading = false;
      localStorage.setItem('list_message', JSON.stringify(res));
    });
  }

}

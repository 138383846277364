import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { API_URL, HangSo } from '../_models/constant';

@Component({
  selector: 'app-teacher-lesson',
  templateUrl: './teacher-lesson.component.html',
  styleUrls: ['./teacher-lesson.component.css']
})
export class TeacherLessonComponent implements OnInit {
  token: string;
  loading = true;
  update_status: any;
  app_data: any;
  list_student: any;
  current_class_id: any;
  current_class_name: any;
  paging: any;
  current_page = 1;
  list_lesson: any;
  list_lesson_unit: any;
  current_lesson_id = 1;
  lesson_new = false;
  add_lesson = false;
  num_last_lesson: number;
  limit_get = 10;

  constructor(
    private http: HttpClient,
    public hs: HangSo
  ) { }

  ngOnInit() {
    this.token = this.hs.token2018();
    this.loading = true;
    this.current_class_id = localStorage.getItem('current_class_id');
    this.app_data = JSON.parse(localStorage.getItem('app_data'));
    this.app_data.list_class.forEach(el => {
      if (el.id == this.current_class_id) {
        let number_page: number;
        number_page = Math.ceil(el.number_lesson / this.limit_get);
        this.paging_build(number_page);
        this.current_class_name = el.title;
        this.list_lesson_unit = el.list_lesson_unit;
      }
    });

    this.get_lesson_limit(1);
  }

  paging_build(number_page: number) {
    this.paging = [];
    for (let page = 1; page <= number_page; page++) {
      if (this.current_page === page) {
        this.paging.push({ 'page': page, 'label': page, 'class': 'active' });
      } else {
        this.paging.push({ 'page': page, 'label': page, 'class': '' });
      }
    }
    if (this.current_page === 0) {
      this.paging.push({ 'page': 0, 'label': 'All', 'class': 'active' });
    } else {
      this.paging.push({ 'page': 0, 'label': 'All', 'class': '' });
    }
  }

  get_lesson_limit(page: number) {
    this.loading = true;
    this.current_page = page;
    let url = `${API_URL}/`;
    url = url + 'get_lesson/' + this.current_class_id;
    if (this.limit_get > 0) {
      url = url + '/limit/' + this.limit_get + '/page/' + page;
    }
    this.http.get(url)
      .toPromise()
      .then(res => {
        console.log(res);
        this.app_data = res;
        this.list_lesson = this.app_data.list;
        this.current_lesson_id = this.app_data.current_lesson.lesson;
        this.num_last_lesson = 1 + this.app_data.max_lesson;
        this.loading = false;
        this.lesson_new = true;
        this.add_lesson = false;
        let number_page: number;
        number_page = Math.ceil(this.app_data.max_lesson / this.limit_get);
        this.paging_build(number_page);
      });
  }

  add_lesson_new() {
    this.add_lesson = true;
  }

  onSubmit(formLearning: any) {
    let url = `${API_URL}/user_id/`;
    url = url + localStorage.getItem('currentUserID');
    url = url + '/teacher_lesson';
    const body = JSON.stringify(formLearning.value);
    this.update_status = 1;
    return this.http.post(url, body)
      .toPromise()
      .then(res => {
        this.update_status = 2;
        setTimeout(() => {
          this.update_status = 3;
        }, 1500);
        // res.json();
        // console.log(res);
        this.get_lesson_limit(1);
      });
  }

}

import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { API_URL, count_msg_no_read } from "../_models/constant";

@Component({
  selector: "app-parent-corner",
  templateUrl: "./parent-corner.component.html",
  styleUrls: ["./parent-corner.component.css"],
})
export class ParentCornerComponent implements OnInit {
  loading = true;
  list_news: any;

  // pass to menu top component
  num_message = 0;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.loading = true;
    const url = `${API_URL}/get_parent_corner`;
    if (localStorage.getItem("list_parent_corner") === null) {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          this.list_news = res;
          this.loading = false;
          localStorage.setItem("list_parent_corner", JSON.stringify(res));
        });
    } else {
      this.list_news = JSON.parse(localStorage.getItem("list_parent_corner"));
      this.loading = false;
    }

    // the second loading
    setTimeout(() => {
      const app_data = JSON.parse(localStorage.getItem("app_data"));
      const person_stt = parseInt(localStorage.getItem("current_person_id"), 0);
      this.num_message = count_msg_no_read(app_data, person_stt);
    }, 500);
  }
}

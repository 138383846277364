import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { count_msg_no_read, HangSo } from "../_models/constant";
import { update_read_to_data } from "../_models/funcs";

@Component({
  selector: "app-lesson-detail",
  templateUrl: "./lesson-detail.component.html",
  styleUrls: ["./lesson-detail.component.css"],
})
export class LessonDetailComponent implements OnInit {
  loading = true;
  app_data: any;
  list_lesson: any;
  lesson: any;
  current_person_id: any;

  // pass to menu top component
  num_message = 0;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    public hs: HangSo
  ) {}

  ngOnInit() {
    const idd = this.route.snapshot.paramMap.get("id");
    this.current_person_id = localStorage.getItem("current_person_id");
    const user_id = localStorage.getItem("currentUserID");
    const person_stt = localStorage.getItem("current_person_id");

    this.loading = false;
    this.app_data = JSON.parse(localStorage.getItem("app_data"));
    this.list_lesson =
      this.app_data[`person_${this.current_person_id}`].list_lesson;

    this.lesson = this.list_lesson.find(
      (item: any) => item.app_teacher_lesson_ID == idd
    );

    const check_read = this.list_lesson.find(
      (el: any) => el.app_teacher_lesson_ID == idd && el.read == 1
    );
    if (!check_read) {
      update_read_to_data(this.http, {
        type: "lesson",
        id: idd,
        user_id,
        person_stt,
      });
    }

    // the second loading
    setTimeout(() => {
      const app_data = JSON.parse(localStorage.getItem("app_data"));
      const person_stt = parseInt(localStorage.getItem("current_person_id"), 0);
      this.num_message = count_msg_no_read(app_data, person_stt);
    }, 500);
  }
}

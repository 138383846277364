﻿import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';

// used to create fake backend
import { fakeBackendProvider } from './_helpers/index';

import { HangSo } from './_models/constant';
import { Funcs } from './_models/funcs';

import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { AuthGuard } from './_guards/index';
import { JwtInterceptor } from './_helpers/index';
import { AlertService, AuthenticationService, UserService } from './_services/index';
import { HomeComponent } from './home/index';
import { LoginComponent } from './login/index';

import { LessonDetailComponent } from './lesson-detail/lesson-detail.component';
import { TestReportComponent } from './test-report/test-report.component';

import { ClipSpeakingComponent } from './clip-speaking/clip-speaking.component';
import { CourseComponent } from './course/course.component';
import { HomeTeacherComponent } from './home-teacher/home-teacher.component';
import { MenuTopComponent } from './_template-parts/menu-top.component';
import { MenuBottomComponent } from './_template-parts/menu-bottom.component';
import { MenuBottomTeacherComponent } from './_template-parts/menu-bottom-teacher.component';
import { TeacherPostComponent } from './teacher-post/teacher-post.component';
import { LearningPointComponent } from './learning-point/learning-point.component';
import { TeacherLessonComponent } from './teacher-lesson/teacher-lesson.component';
import { LessonResultComponent } from './lesson-result/lesson-result.component';
import { ParentCornerComponent } from './parent-corner/parent-corner.component';
import { ParentCornerDetailComponent } from './parent-corner-detail/parent-corner-detail.component';
import { MessageComponent } from './message/message.component';
import { MessageDetailComponent } from './message-detail/message-detail.component';
import { LessonResultDetailComponent } from './lesson-result-detail/lesson-result-detail.component';
import { MessageTeacherComponent } from './message-teacher/message-teacher.component';
import { ClipSpeakingUpdateComponent } from './clip-speaking-update/clip-speaking-update.component';
import { TestReportUpdateComponent } from './test-report-update/test-report-update.component';
import { TeacherChatComponent } from './teacher-chat/teacher-chat.component';

@NgModule({
    imports: [
        BrowserModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        FormsModule,
        HttpClientModule,
        routing
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        LessonDetailComponent,
        TestReportComponent,
        ClipSpeakingComponent,
        CourseComponent,
        HomeTeacherComponent,
        MenuTopComponent,
        MenuBottomComponent,
        MenuBottomTeacherComponent,
        TeacherPostComponent,
        LearningPointComponent,
        TeacherLessonComponent,
        LessonResultComponent,
        ParentCornerComponent,
        ParentCornerDetailComponent,
        MessageComponent,
        MessageDetailComponent,
        LessonResultDetailComponent,
        MessageTeacherComponent,
        ClipSpeakingUpdateComponent,
        TestReportUpdateComponent,
        TeacherChatComponent
    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        UserService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },

        // provider used to create fake backend
        fakeBackendProvider,
        HangSo,
        Funcs
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { API_URL, HangSo } from '../_models/constant';

@Component({
  selector: 'app-teacher-post',
  templateUrl: './teacher-post.component.html',
  styleUrls: ['./teacher-post.component.css']
})
export class TeacherPostComponent implements OnInit {
  token: string;
  loading = true;
  uploading = false;
  update_status: any;
  current_class_id: any;
  current_lesson_id: any;
  result_percent = 0;
  app_data: any;
  selectedFile: File = null;
  post_id: any;
  gallery: any;
  files_: any;
  txttitle = '';
  txtcontent = '';
  required = { 'title': true, 'content': true };
  list_post: any;
  act = '';

  constructor(
    private http: HttpClient,
    public hs: HangSo
  ) { }

  ngOnInit() {
    this.token = this.hs.token2018();
    this.loading = false;
    this.gallery = [];
    this.files_ = [];
    this.current_class_id = localStorage.getItem('current_class_id');
    this.current_lesson_id = localStorage.getItem('current_lesson_id');
    this.list_post = '';
  }

  validate_form_keyup() {
    this.update_status = 4;
    if (this.txttitle.trim() === '') {
      this.required.title = true;
    } else {
      this.required.title = false;
    }
    if (this.txtcontent.trim() === '') {
      this.required.content = true;
    } else {
      this.required.content = false;
    }
  }

  onSubmit(formTeacherPost: any) {
    let url = `${API_URL}/user_id/`;
    url = url + localStorage.getItem('currentUserID');
    url = url + '/teacher_post';
    const body = JSON.stringify(formTeacherPost.value);
    this.update_status = 1;
    return this.http.post(url, body)
      .toPromise()
      .then(res => {
        this.update_status = 2;
        this.post_id = res;
        console.log(res);
      });
  }

  addnew() {
    this.update_status = 3;
    this.uploading = false;
    this.files_ = [];
    this.gallery = [];
    this.txttitle = '';
    this.txtcontent = '';
    this.post_id = 0;
  }

  edit() {
    this.gallery = [];
    this.update_status = false;
    this.uploading = false;
    this.result_percent = 0;
  }

  delete(id: number) {
    this.update_status = false;
    this.loading = true;
    let parentElement: any;
    parentElement = event.srcElement.parentElement.parentElement;
    let url = `${API_URL}/delete_post`;
    this.http.post(url, {'token': this.token, 'id' : id})
      .toPromise()
      .then(res => {
        let tg: any;
        tg = res;
        if (tg) {
          parentElement.remove();
        }
        this.loading = false;
      });
  }

  get_list() {
    this.loading = true;
    this.act = 'list';
    let url = `${API_URL}/get_posts/user_id/`;
    url = url + localStorage.getItem('currentUserID');
    this.http.get(url)
      .toPromise()
      .then(res => {
        this.list_post = res;
        console.log(res);
        this.loading = false;
      });
  }

  get_edit(id: number) {
    this.loading = true;
    let url = `${API_URL}/get_post/`;
    url = url + id;
    this.http.get(url)
      .toPromise()
      .then(res => {
        this.loading = false;
        this.act = '';
        this.required.title = false;
        this.required.content = false;
        let data_tg: any;
        data_tg = res;
        this.list_post = '';
        this.files_ = data_tg.files_;
        this.txttitle = data_tg.post_title;
        this.txtcontent = data_tg.post_content;
        this.post_id = data_tg.ID;
      });
  }

  onFileChanged(event: any) {
    for (let index = 0; index < event.target.files.length; index++) {
      this.selectedFile = <File>event.target.files[index];
      this.uploading = true;
      this.onUpload();
    }
  }

  onUpload() {
    const uploadData = new FormData();
    uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
    this.http.post(`${API_URL}/user_id/473/upload`, uploadData,
      {
        reportProgress: true
        , observe: 'events'
      })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.result_percent = Math.round(event.loaded / event.total * 100);
          console.log('Upload Progress: ' + this.result_percent + '%');
        } else if (event.type === HttpEventType.Response) {
          console.log(event);
          this.app_data = event.body;
          if (this.app_data.error === 0) {
            this.files_.push(this.app_data.file_);
            this.gallery.push(this.app_data.file_);
          }
        }
      });
  }

}

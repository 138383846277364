import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import { AnimationFrameScheduler } from 'rxjs/internal/scheduler/AnimationFrameScheduler';
import { DomSanitizer } from '@angular/platform-browser';
import { API_URL } from '../_models/constant';

@Component({
  selector: 'app-lesson-result',
  templateUrl: './lesson-result.component.html',
  styleUrls: ['./lesson-result.component.css']
})
export class LessonResultComponent implements OnInit {
  loading = true;
  update_status: any;
  app_data: any;
  list_student: any;
  current_class_id: any;
  current_class_name: any;
  number_lesson: number;
  finish_lesson_number: any;
  current_lesson_id: any;
  current_lesson_name: any;

  uploading: any;
  file_: any;
  result_percent = 0;
  selectedFile: File = null;

  file_path = 'file path';

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.loading = false;
    this.file_ = { 'url': '', 'path': '' };
    this.current_class_id = localStorage.getItem('current_class_id');
    this.current_lesson_id = localStorage.getItem('current_lesson_id');
    this.app_data = JSON.parse(localStorage.getItem('app_data'));
    this.app_data.list_class.forEach(el => {
      if (el.id == this.current_class_id) {
        this.number_lesson = el.number_lesson;
        this.finish_lesson_number = el.finish_lesson_number;
        this.current_class_name = el.title;
      }
    });
    this.get_list_student(this.current_class_id, this.current_lesson_id);
  }

  change_lesson($event: any) {
    const lesson = $event.target.dataset.id;
    this.current_lesson_id = lesson;
    this.current_lesson_name = $event.target.innerHTML;
    this.list_student = false;
    this.get_list_student(this.current_class_id, this.current_lesson_id);
  }

  get_list_student(class_: any, lesson: any) {
    this.loading = true;
    let url = `${API_URL}/`;
    url = url + 'student_of_class/' + class_;
    this.http.get(url)
      .toPromise()
      .then(res => {
        this.loading = false;
        if (res[0] === undefined) {
          this.list_student = false;
        } else {
          this.list_student = res;
        }
        console.log(this.list_student);

        let i = -1;
        this.list_student.forEach(el => {
          i++;
          if (this.list_student[i].lastest_video_speaking !== null
            && this.list_student[i].lastest_video_speaking !== '') {
            this.list_student[i].lastest_video_speaking = this.sanitizer.bypassSecurityTrustResourceUrl(el.lastest_video_speaking);
          } else {
            this.list_student[i].lastest_video_speaking = '';
          }
        });
      });
  }

  onSubmit(formLessonResult: any) {
    let url = `${API_URL}/user_id/`;
    url = url + localStorage.getItem('currentUserID');
    url = url + '/lesson_result';
    const body = JSON.stringify(formLessonResult.value);
    this.update_status = 1;
    return this.http.post(url, body)
      .toPromise()
      .then(res => {
        this.update_status = 2;
        setTimeout(() => {
          this.update_status = 3;
        }, 1500);
        this.get_list_student(this.current_class_id, this.current_lesson_id);
        console.log(res);
      });
  }

  onFileChanged(event: any) {
    this.selectedFile = <File>event.target.files[0];
    this.uploading = true;
    let el_img = event.srcElement.parentNode;
    this.onUpload(el_img);
    console.log(this.file_);
  }

  onUpload(el_img: any) {
    const uploadData = new FormData();
    uploadData.append('myFile', this.selectedFile, this.selectedFile.name);
    let url = `${API_URL}/user_id/`;
    url = url + localStorage.getItem('currentUserID');
    url = url + '/upload';
    this.http.post(url, uploadData,
      {
        reportProgress: true
        , observe: 'events'
      })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.result_percent = Math.round(event.loaded / event.total * 100);
        } else if (event.type === HttpEventType.Response) {
          this.app_data = event.body;
          if (this.app_data.error === 0) {
            this.file_ = this.app_data.file_;
            const img_outer = '<img class="img" src="' + this.file_.url + '" style="width:90px;height: 65px;">';
            el_img.querySelector('div.img_outer').innerHTML = img_outer;

            const student_stt = el_img.querySelector('input.file_path').getAttribute('data-index');
            this.list_student[student_stt].file_path = this.file_.path;

            setTimeout(() => {
              this.uploading = false;
            }, 1000);
          }
        }
      });
  }

}

import { Component, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-menu-top",
  templateUrl: "menu-top.component.html",
})
export class MenuTopComponent implements OnInit {
  currentUserRoles: string;
  path_img: any;
  app_data: any;
  current_person_id: any;
  person: any;
  list_lesson: any;
  list_news: any;

  @Input() num_message: any;

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit() {
    console.log("num_message: ", this.num_message);

    if (this.route.snapshot.routeConfig.path.indexOf("/") > 0) {
      this.path_img = "./../../assets/images";
    } else {
      this.path_img = "./../assets/images";
    }

    this.currentUserRoles = localStorage.getItem("currentUserRoles");
    if (this.currentUserRoles == null) {
      this.currentUserRoles = "subscriber";
    }
  }
}

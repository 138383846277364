﻿import { Component, OnInit, Inject } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { HttpClient } from "@angular/common/http";

import { User } from "../_models/index";
import { UserService } from "../_services/index";

import "rxjs/add/operator/toPromise";

import { DOCUMENT } from "@angular/common";

import {
  API_URL,
  convert_to_dateYMD,
  count_msg_no_read,
} from "../_models/constant";

@Component({
  moduleId: module.id,
  templateUrl: "home.component.html",
})
export class HomeComponent implements OnInit {
  users: User[] = [];
  app_data: any;
  list_news: any;
  list_lesson: any;
  list_test_report: any;
  list_clip_speaking: any;
  loading = true;
  person: any;
  current_person: any;
  current_person_id: any;
  have_person = true;
  gallery_big: any;

  window: any;

  // pass to menu top component
  num_message = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  timeout() {
    setTimeout(() => {
      const time_reset = 10 * 60;
      let time_update_current: any;
      time_update_current = Date.now() / 1000;
      time_update_current = parseInt(time_update_current, 0);
      if (localStorage.getItem("time_update") === null) {
        localStorage.setItem("time_update", time_update_current);
      } else {
        let time_update: any;
        time_update = localStorage.getItem("time_update");
        time_update = parseInt(time_update, 0);
        if (time_update_current - time_update >= time_reset) {
          localStorage.setItem("time_update", time_update_current);
          const app_url = this.app_url();
          this.loading = true;
          this.http
            .get(app_url)
            .toPromise()
            .then((res) => {
              localStorage.setItem("app_data", JSON.stringify(res));
              this.loading = false;
              this.app_data = res;
              this.set_current_person();
              this.sort_news();
              this.list_lesson = this.sort_lesson(this.list_lesson);
            });
        }
      }
      this.timeout();
    }, 1000);
  }

  app_url() {
    let app_url: any;
    app_url = `${API_URL}/user_id/`;
    app_url = app_url + localStorage.getItem("currentUserID");
    return app_url;
  }

  ngOnInit() {
    this.timeout();

    // the second loading
    setTimeout(() => {
      this.num_message = count_msg_no_read(
        this.app_data,
        this.current_person_id
      );
    }, 500);

    if (this.window.ReactNativeWebView) {
      let user = window.localStorage.getItem("currentUser") || "{}";
      user = JSON.parse(user);
      user["screen"] = "home";
      user = JSON.stringify(user);
      this.window.ReactNativeWebView.postMessage(user);
      console.log(`Home page component, user: ${user}`);
    }

    this.gallery_big = { image: "/../assets/images/no-image.jpg" };

    this.person = {};
    const person_id = this.route.snapshot.paramMap.get("id");

    if (localStorage.getItem("currentUserRoles") != "subscriber") {
      this.router.navigate(["home-teacher"]);
    }

    const app_url = this.app_url();
    if (person_id === null) {
      this.current_person_id = localStorage.getItem("current_person_id");
    } else {
      this.current_person_id = person_id;
      localStorage.setItem("current_person_id", person_id);
    }

    if (localStorage.getItem("app_data") === null) {
      this.http
        .get(app_url)
        .toPromise()
        .then((res) => {
          this.app_data = res;

          // the first loading
          this.num_message = count_msg_no_read(
            this.app_data,
            this.current_person_id
          );

          localStorage.setItem("app_data", JSON.stringify(this.app_data));
          this.loading = false;
          this.set_current_person();
          this.sort_news();
          this.list_lesson = this.sort_lesson(this.list_lesson);
          this.get_big_image();
        });
    } else {
      this.app_data = JSON.parse(localStorage.getItem("app_data"));
      this.loading = false;
      this.set_current_person();
      this.sort_news();
      this.list_lesson = this.sort_lesson(this.list_lesson);
      this.get_big_image();
    }
  }

  get_big_image() {
    this.app_data.list_person_short.forEach((person: any) => {
      if (person.id == this.current_person_id) {
        this.gallery_big = { image: person.image };
      }
    });
  }

  sort_news() {
    let list_message: any;
    list_message = [];

    /* list news */
    this.list_news.forEach((el: any) => {
      let arr_tg: any;
      arr_tg = { date: el.date };
      arr_tg["date_sort"] = convert_to_dateYMD(el.date);
      arr_tg["link"] = "/message-detail/" + el.id;
      arr_tg["class"] = el.read == 1 ? "read" : "no-read";
      arr_tg["title"] = el.title;
      arr_tg["text_class_css"] = el.text_class_css;
      list_message.push(arr_tg);
    });
    this.list_news = list_message;

    /* sort by desc date_sort*/
    this.list_news.sort(function (a: any, b: any) {
      let nameA, nameB: string;
      nameA = a.date_sort.toUpperCase();
      nameB = b.date_sort.toUpperCase();
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    });
  }

  sort_lesson(_list_lesson: any) {
    let list: any = [];

    _list_lesson.forEach((el: any) => {
      let arr_tg: any;
      arr_tg = { date: el.date };
      arr_tg["date_sort"] = convert_to_dateYMD(el.date);
      arr_tg["link"] = "/lesson-detail/" + el.app_teacher_lesson_ID;
      arr_tg["class"] = el.read == 1 ? "read" : "no-read";
      arr_tg["title"] = " B" + el.lesson + " | " + el.date + " | " + el.title;
      list.push(arr_tg);
    });

    /* sort by desc date_sort*/
    list = list.sort(function (a: any, b: any) {
      let nameA, nameB: string;
      nameA = a.date_sort.toUpperCase();
      nameB = b.date_sort.toUpperCase();
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0;
    });

    return list;
  }

  set_current_person() {
    if (this.app_data.list_person_short.length === 0) {
      this.have_person = false;
    }

    const person_id = this.current_person_id;
    this.person = this.app_data[`person_${person_id}`];
    this.list_lesson = this.app_data[`person_${person_id}`].list_lesson;
    this.list_news = this.app_data[`list_news_person_${person_id}`];
    this.list_test_report = this.app_data[`person_${person_id}`].test_report;
    this.list_clip_speaking =
      this.app_data[`person_${person_id}`].clip_speaking;

    let arr: any;
    arr = this.person.name.split(" ");
    this.person.name = arr[arr.length - 2] + " " + arr[arr.length - 1];
  }
}
